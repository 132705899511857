import React from 'react'
// import { graphql, Link } from "gatsby";
import Layout from '../components/layout'
// import InstaPile from '../components/InstaPile'

// console.log(hero);

export default ({ data }) => (
  <Layout>
    <div className="container">
      <h1>About</h1>

      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/3 mb-8 px-4">
          <h4>Home</h4>
        </div>
        <div className="w-full md:w-2/3 px-4 mb-8" />
      </div>
    </div>
  </Layout>
)
// <InstaPile />
